import { Component, OnInit, Input } from '@angular/core';
import { ContentPage } from '../../../content/interfaces/content-page';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input() menuItems: ContentPage[];

  // tslint:disable-next-line:max-line-length
  // contactUri = 'https://www.google.nl/maps/dir//Eekwal+3,+8011+LA+Zwolle/@52.5108761,6.0865906,17z/data=!4m17!1m7!3m6!1s0x47c7df2e9217d69d:0x1f4ea459015f888e!2sEekwal+3,+8011+LA+Zwolle!3b1!8m2!3d52.5108761!4d6.0887793!4m8!1m0!1m5!1m1!1s0x47c7df2e9217d69d:0x1f4ea459015f888e!2m2!1d6.0887793!2d52.5108761!3e1';

  constructor() {}

  ngOnInit() {}
}
